import { Panel } from '../../App';
import { Charts } from './charts/charts';
import { Heatmap } from './heatmap/heatmap';
import { Overview } from './overview/overview';

interface MainProps {
    panel: Panel;
}

export const Main = (props: MainProps) => {
    return(
        <div className="main">
            {props.panel == "Charts" && <Charts />}
            {props.panel == "Overview" && <Overview />}
            {props.panel == "Heatmap" && <Heatmap />}
            {/* alerts */}
        </div>
    )
}