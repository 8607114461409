import './sidebar.css';
import { useState, useEffect } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const Sidebar = () => {

    const { sendMessage, lastMessage, readyState } = useWebSocket("wss://highlowticker.com/ws/", { shouldReconnect: (closeEvent) => true });
    const [history, setHistory] = useState<any>({highs: [], lows: []});

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            const tick = data.msg;
            // console.log(tick);
            if (tick.isHigh) {
                let highs = history.highs;
                highs.unshift({symbol: tick.symbol, price: tick.high.price, count: tick.high.count});
                if (highs.length > 250) {
                    highs.pop();
                }
            } else {
                let lows = history.lows;
                lows.unshift({symbol: tick.symbol, price: tick.low.price, count: tick.low.count});
                if (lows.length > 250) {
                    lows.pop();
                }
            }
        }
      }, [lastMessage]);

    return (
    <div className="sidebar_container">
        <div className="ticker">
            <div className="ticker_header">
                <div className="high">
                    New Highs
                </div>
                <div className="low">
                    New Lows
                </div>
            </div>
            <div className="ticker_symbols">
                <div className="high">
                    {history.highs.map((h: any) => {
                        return <div className="row">
                        <div className="symbol">
                            {h.symbol}
                        </div>
                        <div className="price">
                            {h.price}
                        </div>
                        <div className="count">
                            {h.count}
                        </div>
                    </div>
                    })}
                </div>
                <div className="low">
                    {history.lows.map((l: any) => {
                        return <div className="row">
                        <div className="symbol">
                            {l.symbol}
                        </div>
                        <div className="price">
                            {l.price}
                        </div>
                        <div className="count">
                            {l.count}
                        </div>
                    </div>
                    })}
                </div>
            </div>
            <div className="ticker_status">
                <span className={`circle ${readyState != 1 ? 'yellow' : ''}`}></span>{readyState == 1 ? "Connected" : "Connecting"}
            </div>
        </div>
    </div>
  )
}