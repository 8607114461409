import "./charts.css"
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

export const Charts = () => {
  return (
    <div className="charts_container">
        <div className="charts">
          {["BTCUSDT.P", "ETHUSDT.P", "SOLUSDT.P", "DOGEUSDT.P"].map(s => {
            return <div className="chart">
              <AdvancedRealTimeChart 
              theme="dark" 
              autosize
              symbol={s}
              interval="5"
              hide_top_toolbar
              hide_side_toolbar
              withdateranges={false}
              details={false}
              disabled_features={["adaptive_logo","control_bar","display_market_status"]}
              ></AdvancedRealTimeChart>
            </div>
          })}
        </div>
    </div>
  )
    
}