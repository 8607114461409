import React, { useState } from 'react';
import logo from './logo.svg';
import { Navbar } from './components/navbar/navbar';
import { Main } from './components/main/main';
import { Sidebar } from './components/sidebar/sidebar';
import { Auth } from './components/auth/auth';
import './App.css';

export type Panel = "Charts" | "Overview" | "Heatmap" | "Alerts";

function App() {
  const [panel, setPanel] = useState<Panel>("Charts");
  
  return (
    <React.Fragment>
      <div className="app_container">
        <Navbar setPanel={setPanel} />
        <Main panel={panel} />
        <Sidebar />
      </div>
    </React.Fragment>
  );
}

export default App;
