import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Logo from './hlt_logo.png';
import './auth.css';

interface AuthProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export type AuthPage = "LOGIN" | "REGISTER" | "FORGOTPASSWORD";

export const Auth = (props: AuthProps) => {
    const [page, setPage] = useState<AuthPage>("REGISTER");
    const [isError, setIsError] = useState<boolean>(false);

    // reset state when close modal
    useEffect(() => {
        if (props.isOpen == false) {
            setIsError(false);
            setPage("LOGIN");
        }
    }, [props.isOpen])

    // clear error messages when switching windows
    useEffect(() => {
        setIsError(false);
    }, [page])

    if (props.isOpen) {
        return (
            <div className="auth_container" onClick={() => props.setIsOpen(false)}>
                <div className="panel" onClick={e => e.stopPropagation()}>
                    {page == "LOGIN" && <Login page={page} setPage={setPage} isError={isError} setIsError={setIsError} />}
                    {page == "REGISTER" && <Register page={page} setPage={setPage} isError={isError} setIsError={setIsError} />}
                    {page == "FORGOTPASSWORD" && <ForgotPassword page={page} setPage={setPage} isError={isError} setIsError={setIsError} />}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

interface PanelProps {
    page: AuthPage;
    setPage: Dispatch<SetStateAction<AuthPage>>;
    isError: boolean;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

const Login = (props: PanelProps) => {

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const signIn = () => {
        // clear error
        setError("");
        // check email
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
            setError("Enter a valid email address");
        } else if (password == "" || password.length < 5) {
            setError("Enter a valid password");
        } else {
            // loading spinner
            setLoading(true);
            setTimeout(() => {
                // send invalid password
                setLoading(false);
                setError("Invalid username or password");
            }, 2500)
        }
    }

    return (
        <div className="login">
            <div className="logo">
                <img src={Logo} />
            </div>
            <div className="field">
                <div className="label">
                    Email
                </div>
                <input type="text" onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="field">
                <div className="label">
                    Password
                </div>
                <input type="password" onChange={e => setPassword(e.target.value)} />
            </div>
            <div className="link" onClick={() => props.setPage("FORGOTPASSWORD")}>
                Forgot Password
            </div>
            <button onClick={() => signIn()}>
                {!loading && "SIGN IN"}
                {loading && <div className="lds-facebook"><div></div><div></div><div></div></div>}
            </button>
            {error != "" && <div className="error_message">
                {error}
            </div>}
            <div className="or">
                or
            </div>
            <div className="link" onClick={() => props.setPage("REGISTER")}>
                Create Account
            </div>
        </div>
    )
}

const Register = (props: PanelProps) => {

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const register = () => {
        setError("");
        // check email
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
            setError("Enter a valid email address");
        } else if (password == "" || password.length < 5) {
            setError("Enter a valid password");
        } else if (password != confirmPassword) {
            setError("Passwords must match");
        } else {
            // loading spinner
            setLoading(true);
            setTimeout(() => {
                // send invalid password
                setLoading(false);
                setError("Account registrations are temporarily disabled");
            }, 2500)
        }
        // check password valid
        // check password matches
        // loading button
        // send unable to create account code
    }

    return (
        <div className="register">
            <div className="logo">
                <img src={Logo} />
            </div>
            <div className="field">
                <div className="label">
                    Email
                </div>
                <input type="text" onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="field">
                <div className="label">
                    Password
                </div>
                <input type="password" onChange={e => setPassword(e.target.value)} />
            </div>
            <div className="field">
                <div className="label">
                    Confirm Password
                </div>
                <input type="password" onChange={e => setConfirmPassword(e.target.value)} />
            </div>
            <button onClick={() => register()}>
                {!loading && "CREATE ACCOUNT"}
                {loading && <div className="lds-facebook"><div></div><div></div><div></div></div>}
            </button>
            {error != "" && <div className="error_message">
                {error}
            </div>}
            <div className="or"></div>
            <div className="link" onClick={() => props.setPage("LOGIN")}>
                Back to log in
            </div>
        </div>
    )
}

const ForgotPassword = (props: PanelProps) => {

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const reset = () => {
        // clear error
        setError("");
        setSuccess("");
        // check email
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
            setError("Enter a valid email address");
        } else {
            // loading spinner
            setLoading(true);
            setTimeout(() => {
                // send invalid password
                setLoading(false);
                setSuccess("Successfully sent! Check email for password reset link");
            }, 2500)
        }
    }

    return (
        <div className="reset">
            <div className="logo">
                <img src={Logo} />
            </div>
            <div className="field">
                <div className="label">
                    Email
                </div>
                <input type="text" onChange={e => setEmail(e.target.value)} />
            </div>
            <button onClick={() => reset()}>
                {!loading && "RESET PASSWORD"}
                {loading && <div className="lds-facebook"><div></div><div></div><div></div></div>}
            </button>
            {error != "" && <div className="error_message">
                {error}
            </div>}
            {success != "" && <div className="success_message">
                {success}
            </div>}
            <div className="or"></div>
            <div className="link" onClick={() => props.setPage("LOGIN")}>
                Back to log in
            </div>
        </div>
    )
}